import $ from 'jquery'

$(function () {
  if (typeof window.Application === 'undefined') {
    window.Application = {};
  }
  window.Application.updateQueryString = function (key, value, url) {
    var hash, re, separator;
    if (!url) {
      url = window.location.href;
    }
    re = new RegExp("([?&])" + key + "=.*?(&|#|$)(.*)", "gi");
    if (re.test(url)) {
      if (typeof value !== "undefined" && value !== null) {
        return url.replace(re, "$1" + key + "=" + value + "$2$3");
      } else {
        hash = url.split("#");
        url = hash[0].replace(re, "$1$3").replace(/(&|\?)$/, "");
        if (typeof hash[1] !== "undefined" && hash[1] !== null) {
          url += "#" + hash[1];
        }
        return url;
      }
    } else {
      if (typeof value !== "undefined" && value !== null) {
        separator = (url.indexOf("?") !== -1 ? "&" : "?");
        hash = url.split("#");
        url = hash[0] + separator + key + "=" + value;
        if (typeof hash[1] !== "undefined" && hash[1] !== null) {
          url += "#" + hash[1];
        }
        return url;
      } else {
        return url;
      }
    }
  };
  $('#estimate_location').on('change', function (event) {
    var val;
    val = $(event.target).val();
    return window.location = window.Application.updateQueryString('location', val);
  });
  $("#filter_model_top, #filter_model_table").on('change', function (event) {
    var input, model, target_url;
    input = $(this);
    target_url = input.data("targetUrl");
    model = input.val();
    if (model) {
      target_url = window.Application.updateQueryString('model', model, target_url);
    }
    return window.location = target_url;
  });
  $("#filter_model_range_table").on("change", function () {
    var input, model_range, target_url;
    input = $(this);
    target_url = input.data("targetUrl");
    model_range = input.val();
    if (model_range) {
      return window.location = target_url.replace("MODEL_RANGE", model_range);
    }
  });
  $("#filter_repair_table").on("change", function () {
    var input, repair, target_url;
    input = $(this);
    target_url = input.data("targetUrl");
    repair = input.val();
    if (repair) {
      return window.location = target_url.replace("REPAIR", repair);
    }
  });
  $(".model-cost-quote").on('click', function (event) {
    return event.preventDefault();
    // TODO: Re-implement this when button has been added
  });
  return $("#unpriced-content-toggle").on("click", function (event) {
    var $unpriced;
    event.preventDefault();
    $unpriced = $(".unpriced");
    if ($unpriced.is(":visible")) {
      $(event.target).text("Show ∨");
    } else {
      $(event.target).text("Hide ∧");
    }
    return $unpriced.slideToggle();
  });
});
